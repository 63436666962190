<template>
  <div class="contactUs">
    <div class="ziHead"></div>
    <div class="ziNav">
      <div class="navMain">
        <div class="navMainRight">
          <span>
            <router-link to="/"><i class="iconfont">&#xe689;</i> <span> &nbsp;> </span> </router-link>
          </span>
          <a href="/contactUs/0082">联系我们 </a>
        </div>
      </div>
    </div>
    <div class="ziBase">
      <div class="ziContact">
        <div class="rowContact">
          <list-detail ref="listDetail" :id="listId"></list-detail>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import listDetail from '@/components/listDetail/index.vue'
export default {
  components: {
    listDetail
  },
  data() {
    return {
      listId: '',
      content: ''
    }
  },
  computed: {},
  mounted() {
    var _this = this

    _this.listId = _this.$route.params.id
  },
  methods: {}
}
</script>

<style lang="scss">
@font-face {
  font-family: 'iconfont'; /* Project id 3165120 */
  src: url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff2?t=1644917438066') format('woff2'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.woff?t=1644917438066') format('woff'),
    url('//at.alicdn.com/t/font_3165120_61xe2w37jbi.ttf?t=1644917438066') format('truetype');
}
.iconfont {
  font-family: 'iconfont' !important;
  font-size: 22px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.1px;
  -moz-osx-font-smoothing: grayscale;
}
.contactUs {
  width: 100%;
  height: auto;
  overflow: hidden;
  min-height: 902px;
}
.ziHead {
  width: 100%;
  height: 417px;
  overflow: hidden;
  background: url(../../assets/image/zihead.jpg) center no-repeat;
  background-size: 100% 100%;
}

.ziNav {
  height: 42px;
  border-bottom: 1px #cacaca solid;
  margin-top: 15px;
  width: 100% !important;
}
.navMain {
  width: 1200px;
  height: auto;
  overflow: hidden;
  margin: 0 auto;
}

.navMainRight {
  width: 300px;
  float: right;
  overflow: hidden;
  font-size: 13px;
  color: #a2a2a2;
  margin-top: 15px;
}
.navMainRight a {
  float: none;
  font-size: 13px;
  color: #a2a2a2;
}
.ziBase {
  width: auto;
  height: auto;
  overflow: hidden;
}
.ziContact {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.rowContact {
  margin-top: 0px;
  padding-bottom: 20px;
}
.contactView {
  width: 1200px;
  overflow: hidden;
  margin: 0 auto;
  box-shadow: 0px 1px 4px #333333;
  padding: 30px 50px;
  background-color: #ffffff;
  line-height: 24px;
}
.contactBody {
  font-family: '宋体';
  font-size: 18px;
  line-height: 30px;
}
.contactBody p {
  padding: 0px;
  line-height: 3em;
  text-indent: 0em;
  font-family: 宋体;
  font-size: 15px;
  margin-top: 0px;
  margin-bottom: 0px;
  white-space: normal;
}
@media (max-width: 1300px) {
  .contactUs .navMain,
  .companyProfile {
    width: 1000px;
  }
  .contactUs .navMainLeft,
  .contactUs .navMainRight {
    width: auto;
  }
}
@media (max-width: 992px) {
  .contactUs .navMain,
  .companyProfile {
    width: 96%;
  }
  .companyProfile {
    padding: 20px 20px;
  }
  .companyProfile .contactBody video {
    max-width: 100% !important;
  }
  .companyProfile img {
    max-width: 100% !important;
  }
  .contactUs .ziHead {
    height: 362px;
  }
}
@media (max-width: 768px) {
  .contactUs .navMainLeft {
    width: 100%;
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: auto;
  }
  .contactUs .navMainRight {
    display: none;
  }
  .contactUs .ziHead {
    height: 200px;
  }
  .contactUs .ziContact {
    padding: 0;
    margin: 0;
  }
  .companyProfile {
    padding: 20px 10px;
  }
  .contactBody h1 {
    font-size: 22px !important;
  }
}
</style>
